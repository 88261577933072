<template>
  <div>
    <b-row class="align-items-center">
      <b-button size="sm" variant="dark" class="handle px-3 ml-2 mr-2" style="cursor:move">{{ idx+1 }}</b-button>
      <b-col md="auto">
        <b-input-group size="sm">
          <b-form-select size="sm" class="mr-2" v-model="item.search_type" :options="search_type" title="Search Type"></b-form-select>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group size="sm" prepend="Durată:">
          <b-form-input v-model="item.search_time" type="number" placeholder="sec" title="Search Time"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group size="sm" :prepend="(['by_zone', 'by_near_zone'].includes(item.search_type)) ? 'Limita șoferi' : 'Distanță'" >
          <b-form-input v-model="item.search_limit" type="number" :placeholder="(item.search_type === 'by_zone' || item.search_type === 'by_near_zone') ? 'nr' : 'metri'" title="Limit"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group size="sm" prepend="Prioritate:">
          <b-form-select size="sm" class="mr-2" v-model="item.search_order_by" :options="search_order_by" title="Search Order By"></b-form-select>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group size="sm" prepend="Notificare:">
          <b-form-select size="sm" class="mr-2" v-model="item.notification_method" :options="notification_method" title="Notification Method"></b-form-select>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group size="sm">
          <b-form-select size="sm" class="mr-2" v-model="item.exclude_previous" :options="exclude_previous"></b-form-select>
        </b-input-group>
      </b-col>
      <b-col md="auto">
        <b-dropdown text="Condiții refuzare" size="sm" dropleft :variant="item.ban_conditions.active ? 'success' : 'secondary'">
          <b-dropdown-text style="width: 500px; font-weight: 600;">
            <b-form-checkbox
            class="my-3"
              v-model="item.ban_conditions.active"
            >
              Activare
            </b-form-checkbox>
          <b-dropdown-divider v-if="item.ban_conditions.active"></b-dropdown-divider>
            <b-row class="align-items-center" v-if="item.ban_conditions.active">
              <b-col md="5">
                Acțiune:
              </b-col>
              <b-col md="7">
                  <b-form-select v-model="item.ban_conditions.type" class="my-1" size="sm" value-field="value" :options="ban_types"></b-form-select>
              </b-col>
              <b-col md="5">
                Șoferi:
              </b-col>
              <b-col md="7">
                <b-input-group class="my-1" size="sm">
                  <b-form-input v-model.number="item.ban_conditions.drivers.from" type="number" placeholder="de la:"></b-form-input>
                  <b-form-input v-model.number="item.ban_conditions.drivers.to" type="number" placeholder="până la:"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col md="5">
                Maxim comenzi refuzate:
              </b-col>
              <b-col md="7">
                  <b-form-input v-model.number="item.ban_conditions.count" class="my-1" type="number" size="sm"></b-form-input>
              </b-col>
              <b-col md="5">
                Interval resetare (min):
              </b-col>
              <b-col md="7">
                  <b-form-input v-model.number="item.ban_conditions.reset_interval" class="my-1" type="number" size="sm"></b-form-input>
              </b-col>
            </b-row>
          </b-dropdown-text>
        </b-dropdown>
      </b-col>
      <b-col md="auto">
        <b-button size="sm" variant="danger" @click="$emit('remove', idx)"> <b-icon icon="x"></b-icon> </b-button>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  name: 'BuilderItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    }
  },
  created () {
    if (!this.item.ban_conditions) {
      this.item.ban_conditions = {
        active: false,
        type: 'ban',
        drivers: {
          from: 1,
          to: 1
        },
        count: 5,
        reset_interval: 60
      }
    }
  },
  data () {
    return {
      id: false,
      items: [
        {
          id: 0,
          ban: false,
          search_type: null,
          search_time: null,
          search_limit: null,
          search_order_by: null,
          notification_method: null,
          exclude_previous: null,
          ban_conditions: {
            active: false,
            type: 'ban',
            drivers: {
              from: 1,
              to: 1
            },
            count: 5,
            reset_interval: 60
          }
        }
      ],
      search_type: [
        { value: null, text: '- tip căutare' },
        { value: 'by_zone', text: 'În zonă' },
        { value: 'by_near_zone', text: 'În zona apropiată' },
        { value: 'by_near_stand', text: 'În stație apropiată (linear)' },
        { value: 'by_near_stand_with_route', text: 'În stație apropiată (ruta)' },
        { value: 'by_near_stand_and_polygon', text: 'În stație si zona' },
        { value: 'by_radius', text: 'În rază' },
        { value: 'by_route', text: 'Pe traseu' }
      ],
      search_order_by: [
        { value: null, text: '- alege' },
        { value: 'by_distance', text: 'După distanță' },
        { value: 'by_route_distance', text: 'Pe traseu' },
        { value: 'by_idle_from', text: 'Cel mai vechi' },
        { value: 'any', text: 'Oricine' }
      ],
      notification_method: [
        { value: null, text: '- notificare' },
        { value: 'all', text: 'Toți' },
        { value: 'pending', text: 'Alertă' },
        { value: 'pending_pinned', text: 'Alertă (noua)' },
        { value: 'by_rotation', text: 'Pe rând' }
      ],
      exclude_previous: [
        { value: null, text: '- exclude precedent' },
        { value: true, text: 'Exclude precedent' },
        { value: false, text: 'Nu exclude precedent' }
      ],
      ban_types: [
        {
          text: 'Blocare',
          value: 'ban'
        },
        {
          text: 'Resetare timp',
          value: 'reset'
        }
      ],
      dragging: false
    }
  }
}
</script>
