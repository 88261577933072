<template>
  <header class="header">
    <div class="container-fluid">
    <b-row align-v="center">
      <b-col cols="1">
        <router-link to="/" class="taxi-brand">
          TAXI PMI
        </router-link>
      </b-col>
      <b-col  cols="12" md="auto">
        <b-nav>
          <b-nav-item disabled to="/" active>Blog</b-nav-item>
          <b-nav-item disabled to="#">Despre</b-nav-item>
          <b-nav-item to="#">Contact</b-nav-item>
        </b-nav>
      </b-col>
      <b-col>

        <b-dropdown variant="success" size="sm"  ref="dropdown" no-caret class="pull-right" right >
          <template #button-content><b-icon icon="key"></b-icon> Intră în cont</template>
          <b-dropdown-form style="width:240px">
          <b-form  @submit.prevent="onSubmit">
            <b-form-group label="Adresa e-mail:" label-for="dropdown-form-email" >
              <b-form-input
                id="dropdown-form-email"
                size="sm"
                v-model="form.email"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Parola:" label-for="dropdown-form-password">
              <b-form-input
                id="dropdown-form-password"
                type="password"
                size="sm"
                v-model="form.password"
              ></b-form-input>
            </b-form-group>

            <b-button variant="outline-success" class="w-100 py-2" size="sm" type="submit">Intră în cont</b-button>
            </b-form>
          </b-dropdown-form>
        </b-dropdown>
        <b-button variant="outline-success" size="sm" to="/business/enroll" class="pull-right d-none mr-2"><b-icon icon="person"></b-icon> Înregistrare</b-button>
      </b-col>
    </b-row>
  </div>
  </header>
</template>

<script>
import auth from '@/services/auth'
import router from '@/router'

export default {
  name: 'AppHeader',
  data () {
    return {
      timeNow: '',
      form: {
        email: '',
        password: ''
      }
    }
  },
  created () {
    setInterval(this.getClock, 1000)
  },
  methods: {
    onSubmit () {
      auth.login(this.form.email, this.form.password)
        .then(() => {
          router.push('/dashboard')
          this.$toasted.success('V-ați conectat cu succes!')
        })
    },
    getClock: function () {
      this.timeNow = this.$day().format('HH:mm:ss')
    }
  }
}
</script>

<style scoped>

</style>
